import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { mappingPageIdUrls } from '../../constants/mappingPageIdUrls'

const defaultDetailsQuery = graphql`
  query PageSettings {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
      socialImage {
        asset {
          _id
          url
        }
      }
      seopages {
        description
        keywords
        title
        pageId
        image {
          asset {
            _id
            url
          }
        }
      }
    }
  }
`

class SEO extends Component {
  static defaultProps = {
    lang: 'en',
    meta: [],
    keywords: []
  }

  static propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    id: PropTypes.string
  }

  render() {
    const {
      description,
      lang,
      meta = [],
      keywords = [],
      title,
      id,
      image = ''
    } = this.props
    return (
      <StaticQuery
        query={defaultDetailsQuery}
        variables={{ id }}
        render={(data) => {
          const { site } = data
          const metaInfo = {
            title: title || site.title || '',
            description: description || site.description || '',
            keywords: keywords.length ? keywords : site.keywords || [],
            image: site.socialImage
              ? site.socialImage.asset
                ? site.socialImage.asset.url
                : ''
              : image,
            url: `https://blueharvest.io${mappingPageIdUrls[id] || ''}`
          }

          // if (id && site.seopages) {
          //   const seoPageItem = site.seopages.find(p => p.pageId === id)
          //   if (seoPageItem) {
          //     for (const prop in seoPageItem) {
          //       if ((!!seoPageItem[prop] && !Array.isArray(seoPageItem[prop])) || (Array.isArray(seoPageItem[prop]) && seoPageItem[prop].length)) {
          //         if (prop !== 'image') {
          //           metaInfo[prop] = seoPageItem[prop]
          //         } else if (seoPageItem[prop]['asset']) {
          //           metaInfo[prop] = seoPageItem[prop]['asset']['url']
          //         }
          //       }
          //     }
          //   }
          // }
          return (
            <Helmet
              htmlAttributes={{ lang }}
              titleTemplate={
                id === 'main' ? 'Blue Harvest' : `Blue Harvest - %s`
              }
              defaultTitle='Blue Harvest'
            >
              <title>{metaInfo['title']}</title>
              <meta name='title' content={metaInfo['title']} />
              <meta name='description' content={metaInfo['description']} />
              <meta name='keywords' content={metaInfo['keywords'].join(', ')} />
              <meta property='og:type' content='website' />
              <meta property='og:url' content={metaInfo['url']} />
              <meta property='og:title' content={metaInfo['title']} />
              <meta
                property='og:description'
                content={metaInfo['description']}
              />
              <meta property='og:image' content={metaInfo['image']} />
              <meta property='og:site_name' content='Blue Harvest' />
              <meta name='twitter:site' content='@blueharvest_io' />
              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:url' content={metaInfo['url']} />
              <meta name='twitter:title' content={metaInfo['title']} />
              <meta
                name='twitter:description'
                content={metaInfo['description']}
              />
              <meta name='twitter:image' content={metaInfo['image']} />
              <link rel='image_src' href={metaInfo['image']} />
              <link
                rel='apple-touch-icon'
                sizes='180x180'
                href='/apple-touch-icon.png'
              />
              <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href='/favicon-32x32.png'
              />
              <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href='/favicon-16x16.png'
              />
              <link
                rel='shortcut icon'
                type='image/x-icon'
                href='/favicon.ico'
              />
              <link rel='manifest' href='site.webmanifest' />
              <link
                rel='mask-icon'
                href='/safari-pinned-tab.svg'
                color='#5bbad5'
              />
              <meta name='msapplication-TileColor' content='#da532c' />
              <meta name='theme-color' content='#1e212c' />
              <meta itemProp='description' content={metaInfo['description']} />
              <meta itemProp='image' content={metaInfo['image']} />
            </Helmet>
          )
        }}
      />
    )
  }
}

export default SEO
